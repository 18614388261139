.ShareMapItem {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: middle;
    //box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    filter: drop-shadow(8px 21px 7px #89AEFF) drop-shadow(-8px -8px 60px rgba(137, 174, 255, 0.34));
    border-radius: 20px;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
    -o-user-select: none;
    -moz-user-select: none;
    cursor: pointer;
    width: calc((100% - 8.75rem) / 5);

    .share-map-thumb {
        background: rgba(255, 255, 255, 0.25);
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        width: 100%;
        height: 246px;
        border-radius: 20px 20px 0px 0px;
        position: relative;

    }

    .share-map-info {
        width: 100%;
        background: #fff;
        border-radius: 0px 0px 20px 20px;
        text-align: left;
        height: 175px;

        .share-map-title1 {
            font-size: 25px;
            color: #333333;
            line-height: 60px;
            padding-left: 20px;
            //max-width: 22rem;
            max-height: 4rem;
            font-weight: 600;
        }

        .share-map-title2 {
            font-size: 18px;
            color: #333333;
            line-height: 25px;
            padding-left: 20px;
            //max-width: 22rem;
            max-height: 4rem;
            font-weight: 500;
        }

        .share-map-nickname {
            font-size: 16px;
            color: #333333;
            height: 45px;
            padding-left: 20px;
            vertical-align: top;
        }
    }
}


@media (max-width: 1920px) {
    .ShareMapItem {
        width: calc(25% - 1.75rem);
    }
}

@media (max-width: 1600px) {
    .ShareMapItem {
        width: calc(33.333% - 1.75rem);
    }
}

@media (max-width: 1200px) {
    .ShareMapItem {
        width: calc(50% - 1.75rem);
    }
}

@media (max-width: 1024px) {
    .ShareMapItem {
        width: calc(100% - 1.75rem);
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
    }
}