.main{
  width:1440px;
  margin: 0 auto;
}

.NursingSkillIntro {
 /* padding: 0 4rem; */
  

  margin-top: 10rem;


  .first {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
    background: white;

    .contents-1 {
      margin-right: 3%;
      width: 25%;
      background: #FFF;
      border: 1px solid #8168FF;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 350px;
      border-radius: 35px;
      padding-top: 3rem;

      .first-icon {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -50px;
      }

      .first-title {
        text-align: center;
        font-size: 30px;
        color: #8168FF;
        opacity: 0.6;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      .first-text {
        text-align: center;
        font-size: 20px;
        color: #130F26;
        opacity: 0.6;
      }

    }

    .contents-2 {
      margin-right: 3%;
      width: 25%;
      background: #FFF;
      border: 1px solid #8168FF;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 350px;
      border-radius: 35px;
      padding-top: 3rem;

      .first-icon {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -50px;
      }

      .first-title {
        text-align: center;
        font-size: 30px;
        color: #8168FF;
        opacity: 0.6;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      .first-text {
        text-align: center;
        font-size: 20px;
        color: #130F26;
        opacity: 0.6;
      }
    }

    .contents-3 {
      width: 25%;
      background: #FFF;
      border: 1px solid #8168FF;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 350px;
      border-radius: 35px;
      padding-top: 3rem;

      .first-icon {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -50px;
      }

      .first-title {
        text-align: center;
        font-size: 30px;
        color: #8168FF;
        opacity: 0.6;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      .first-text {
        text-align: center;
        font-size: 20px;
        color: #130F26;
        opacity: 0.6;
      }
    }

  }

  .second {
    background: #F2F0F4;
    padding: 2.5rem;
    padding-bottom: 8rem;

    .second-content {
      margin: 0 auto;
      display: flex;
      justify-content: space-evenly;
      width: 80%;

      .second-left {
        max-width: 1080px;
        margin: 0 auto;

        .video {
          position: relative;
          overflow: hidden;

          .video-background {
            max-width: 925px;
            width: 502px;
            margin: 0 auto;
            position: absolute;
            left: 50%;
            top: 42px;
            transform: translateX(-50%);

            .video-desc {
              display: block;
              width: 100%;
              padding-top: 60.25%;
              position: relative;

              .player {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
              }
            }
          }
        }

      }


      .second-right {
        width: 50%;



        .box1 {
          border: 1.73571px solid #7B39FF;
          box-shadow: 0px 6.94286px 20.8286px rgba(0, 0, 0, 0.12);
          border-radius: 20.8286px;
          background: #FFFFFF;
          height: 30%;
          margin: 1.5rem;
          width: 500px;
          display: flex;
          align-items: center;

          .box-icon {
            width: 20%;
            padding: 2rem;
          }

          .text-group {
            width: 80%;
            height: 60%;
            display: flex;
            flex-flow: wrap;
            padding-right: 1rem;

            .text-title {
              p {
                font-weight: 500;
                font-size: 17.3571px;
                color: #7B39FF;
              }

            }

            .text-desc {
              p {
                color: #5B616E;
              }
            }
          }

        }

        .box2 {
          border: 1.73571px solid #20CBBA;
          box-shadow: 0px 6.94286px 20.8286px rgba(0, 0, 0, 0.12);
          border-radius: 20.8286px;
          background: #FFFFFF;
          height: 30%;
          margin: 1.5rem;
          width: 500px;
          display: flex;
          align-items: center;

          .box-icon {
            width: 20%;
            padding: 2rem;
          }

          .text-group {
            width: 80%;
            height: 60%;
            display: flex;
            flex-flow: wrap;
            padding-right: 1rem;

            .text-title {
              p {
                font-weight: 500;
                font-size: 17.3571px;
                color: #20CBBA;
              }
            }

            .text-desc {
              p {
                color: #5B616E;
              }
            }
          }
        }

        .box3 {
          border: 1.73571px solid #D764FF;
          box-shadow: 0px 6.94286px 20.8286px rgba(0, 0, 0, 0.12);
          border-radius: 20.8286px;
          background: #FFFFFF;
          height: 30%;
          margin: 1.5rem;
          width: 500px;
          display: flex;
          align-items: center;

          .box-icon {
            width: 20%;
            padding: 2rem;
          }

          .text-group {
            width: 80%;
            height: 60%;
            display: flex;
            flex-flow: wrap;
            padding-right: 1rem;

            .text-title {
              p {
                font-weight: 500;
                font-size: 17.3571px;
                color: #D764FF;
              }

            }

            .text-desc {
              p {
                color: #5B616E;
              }
            }
          }
        }
      }

    }
  }

  .nursing-skill-intro-wrap {
    .nursing-skill-name {
      text-align: center;
      font-size: 31.5px;
      color: #130F26;
      margin-bottom: 2rem;

      .nursing-skill-name-line {
        width: 8rem;
        border: 2px solid #cdcdcd;
        margin: 2rem auto;
      }
    }

    .nursing-skill-desc {
      text-align: center;
      font-size: 19.5px;
      color: #130F26;
      opacity: 0.6;
      word-break: keep-all;
      width: 100%;
      padding: 0 2rem;
      margin: 0 auto;
      margin-bottom: 5rem;
    }



    .third {
      background: #20CBBA;
      padding-top: 3rem;
      padding-bottom: 3rem;

      .top-text-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 2rem;

        .third-title {
          font-weight: 700;
          font-size: 36px;
          color: #FFFFFF;
        }

        .third-desc {
          font-weight: 500;
          font-size: 24px;
          color: #FFFFFF;
          padding-top: 2rem;
        }
      }

      .nursing-skill-point-list {
        display: flex;
        justify-content: center;
        align-content: center;
        padding: 0 2rem;
        margin: 4rem 0;

        .nursing-skill-point-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          margin: 1rem;
          width: 33.33%;

          .nursing-skill-point-img {
            background: #6633cc;
            width: 14rem;
            height: 14rem;
            border-radius: 50%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: #fff;

            img {
              max-width: 70%;
            }

            p {
              font-size: 36px;
              margin-top: 1rem;
            }
          }

          .nursing-skill-point-desc {
            margin-top: 1rem;
            word-break: keep-all;
            font-size: 30px;
            color: #3e3a39;
            height: 18rem;

            p {
              margin-bottom: 2rem;
            }
          }

          .nursing-skill-point-ex-imgs {
            display: flex;

            .nursing-skill-point-ex-arrow {
              margin: 2rem 0;
            }

            .nursing-skill-point-ex-img {
              border-radius: 16px;
              box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
              margin: 2rem 0;

              img {
                border-radius: 16px;
                max-width: 100%;
                width: 100%;
                height: 14rem;
                object-fit: cover;
                object-position: top;
                border: 18px solid white;
              }
            }

            p {
              font-weight: 600;
              font-size: 20px;
              color: #FFFFFF;
            }
          }
        }
      }

      .third-bottom-group {
        display: flex;
        flex-direction: column;
        width: 75%;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 1rem;
        border: 1px solid #130F26;
        border-radius: 10px;
        font-size: 20px;
        color: #130F26;

        p {
          padding: 0.5rem;
        }
      }
    }


    .four {
      background: #D764FF;
      padding-top: 3rem;
      padding-bottom: 3rem;

      .top-text-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 2rem;

        .third-title {
          font-weight: 700;
          font-size: 36px;
          color: #FFFFFF;
        }
      }

      .nursing-skill-point-list {
        display: flex;
        justify-content: center;
        align-content: center;
        padding: 0 2rem;
        margin: 4rem 0;

        .nursing-skill-point-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          margin: 1rem;
          width: 33.33%;


          .left-group {
            /* width: 16rem; */
            width: 100%;
            height: 14rem;
            text-align: center;
            display: flex;
            justify-content: center;

            .title {

              margin: 2rem 0;
              height: 100%;

              .title-text {
                background: #FFFFFF;
                font-size: 20px;
                color: #D764FF;
                padding: 0.8rem 2rem;
                border-radius: 34.5px;
                width: 50%;
                margin: 0 auto;
              }

              .desc {
                margin-top: 2rem;
                width: 100%;

                p {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 19.5px;
                  color: #FFFFFF;
                }

              }
            }
          }

          .nursing-skill-point-img {
            background: #6633cc;
            width: 14rem;
            height: 14rem;
            border-radius: 50%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: #fff;

            img {
              max-width: 70%;
            }

            p {
              font-size: 36px;
              margin-top: 1rem;
            }
          }

          .nursing-skill-point-desc {
            margin-top: 1rem;
            word-break: keep-all;
            font-size: 30px;
            color: #3e3a39;
            height: 18rem;

            p {
              margin-bottom: 2rem;
            }
          }

          .nursing-skill-point-ex-imgs {
            display: flex;

            .nursing-skill-point-ex-arrow {
              margin: 2rem 0;
            }

            .nursing-skill-point-ex-img {
              border-radius: 16px;
              box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
              margin-top: 2rem;
              margin-bottom: 1rem;

              img {
                border-radius: 16px;
                max-width: 100%;
                width: 100%;
                height: 11rem;
              }
            }

            p {
              font-weight: 600;
              font-size: 20px;
              color: #FFFFFF;
            }
          }
        }
      }

    }




    .nursing-skill-main-point-title {
      text-align: center;
      font-size: 36px;
      color: #130F26;
      margin-bottom: 2rem;
    }

    .nursing-skill-main-point-desc {
      font-size: 24px;
      color: #130F26;
      margin-bottom: 2rem;
      font-weight: 500;
    }

    .five {

      padding-top: 8rem;
      background: #F2F0F4;
      .nursing-skill-main-point1 {
        text-align: center;

        .nursing-skill-main-point1-content {


          .top-content{
            padding-top: 5rem;
            width:100%;
            display: flex;
            justify-content: space-evenly;
            .content-info{

              .btn{
                background: #FFFFFF;
                border-radius: 47.3731px;
                border: 1.37313px solid #20CBBA;
                padding: 0.55rem 4rem;
                color: #20CBBA;
                font-size: 25.5px;
                display: inline-block;
                margin-top: 2rem;
                margin-bottom: 2rem;
                font-weight: 600;
              }

              .desc{
                color: #130F26;
                font-size: 18px;

              }
            }
          }
          
          .nursing-skill-main-point1-img {
            border-radius: 16px;
            margin: 3rem;
            max-width: 100%;
            margin: 0 auto;

            img {
              border-radius: 16px;
              max-width: 100%;
              height: 40rem;
              outline: none;
              margin: 0 auto;
            }
          }

          .nursing-skill-main-point1-summary {
            margin: 4rem 0;

            p {
              font-size: 48px;
              font-weight: 400;
              color: #3e3a39;
              line-height: 1.8;
            }
          }
        }
      }



      .table-group{

      width: 60%;
      margin: 0 auto;
      padding-top: 8rem;
      padding-bottom: 3rem;

      .download-spec-table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
  
        tr {
          text-align: center;
          color: #000000;
          font-size: 20px;
  
          .download-col-first {
  
            width: 30%;
            text-align: center;
            height: 80px;
            border-right: 1.5px solid #D0CDDA;;
          }
  
          .download-col-rest {
            width: 360px;
            height: 80px;
            border-right: 1.5px solid #D0CDDA;;
          }
  
          // td:first-child {
          //     padding-left: 50px;
          //     text-align: left;
          //     font-weight: 500;
          // }
  
          // td {
          //     width: 360px;
          //     height: 80px;
          // }
        }
  
        .download-spec-head {
          background: #95939D;
          border-bottom: 1.5px solid #D0CDDA;
          color: #FFFFFF;
          font-size: 20.75px;
          font-weight: bold;
        }
  
        .download-spec-info {
          background: #ffffff;
          color: #000000;
          font-size: 17px;
          font-weight: 500;
          border-bottom: 1.5px solid #D0CDDA;
          
        }
  
        .download-spec-head-pc {
          background:rgba(255, 203, 0, 0.1);
          border-bottom: 2px solid #FFCB00;
          color: #FFCB00;
          font-size: 25px;
          font-weight: bold;
        }
  
        .download-spec-info-pc {
          background: #ffffff;
          color: #000000;
          font-size: 20px;
          font-weight: 500;
          border-bottom: 2px solid #FFCB00;
        }
  
      }
    }

    }


    .six{
      padding-top: 8rem;
    

    .nursing-skill-main-point2 {
      text-align: center;

      .nursing-skill-main-point2-content {
        .nursing-skill-main-point2-list {
          display: flex;
          justify-content: center;

          .nursing-skill-main-point2-item {
            
            height: 100%;
            margin: 4rem;

            .nursing-skill-main-point2-item-img {
              
              margin: 3rem;
            }

            .nursing-skill-main-point2-summary {
              margin: 4rem 0;

              .point2-summary-title {
                border: 2.33544px solid #7C40FF;
                display: inline-block;
                border-radius: 4.67088px;
                padding: 0.55rem 2rem;
                font-size: 18px;
                color: #7C40FF;
                margin-bottom: 2rem;
                font-weight: 600;
              }

              p {
                font-size: 30px;
                font-weight: 400;
                color: #000;
                line-height: 1.8;
              }
            }
          }
        }
      }
    }
  }

    .nursing-skill-vr {
      margin: 4rem 0;
      text-align: center;

      .nursing-skill-vr-title {
        font-size: 74px;
        font-weight: 500;
        color: #fff;
        background-color: #9933ff;
        display: inline-block;
        border-radius: 60px;
        padding: 0 2rem;
      }

      .nursing-skill-vr-desc {
        font-size: 50px;
        color: #3e3a39;
        font-weight: 400;
        margin: 4rem 0;
      }

      .nursing-skill-vr-list {
        display: flex;
        flex-direction: column;

        .nursing-skill-vr-item {
          padding: 2rem;
          margin-bottom: 4rem;

          .nursing-skill-vr-img {
            img {
              max-width: 100%;
            }
          }

          .nursing-skill-vr-name {
            margin-top: 4rem;
            font-size: 48px;
            color: #000;
            font-weight: 400;
          }
        }
      }
    }

    .nursing-skill-btns {
      display: flex;
      justify-content: center;
      padding-bottom: 4rem;

      .nursing-skill-btn {
        display: inline-block;
        border-radius: 32px;
        padding: 0.5rem 2rem;
        color: #fff;
        font-size: 2rem;
        cursor: pointer;
      }

      .btn-left {
        margin-right: 1rem;
        background: #ff9933;
      }

      .btn-right {
        margin-left: 1rem;

        background: #ff3366;
      }
    }
  }
}

@media all and (max-width: 1280px) {
  .NursingSkillIntro {

    .first{
      width: 100%;
    }

    .nursing-skill-intro-wrap {
      .nursing-skill-name {
        font-size: 75px;
      }

      .nursing-skill-desc {
        font-size: 38px;
      }

      .nursing-skill-point-list {
        .nursing-skill-point-item {
          .nursing-skill-point-img {
            p {
              font-size: 28px;
            }
          }

          .nursing-skill-point-desc {
            font-size: 24px;
          }

          .nursing-skill-point-ex-imgs {
            .nursing-skill-point-ex-img {
              img {
                height: 10rem;
              }
            }
          }
        }
      }

      .nursing-skill-main-point-title {
        font-size: 58px;
      }

      .nursing-skill-main-point-desc {
        font-size: 46px;
      }

      .nursing-skill-main-point1 {
        .nursing-skill-main-point1-content {
          .nursing-skill-main-point1-img {
            img {
              height: auto;
            }
          }

          .nursing-skill-main-point1-summary {
            p {
              font-size: 38px;
            }
          }
        }
      }

      .nursing-skill-main-point2 {
        .nursing-skill-main-point2-content {
          .nursing-skill-main-point2-list {
            .nursing-skill-main-point2-item {
              .nursing-skill-main-point2-item-img {
                img {
                  height: 18rem;
                }
              }

              .nursing-skill-main-point2-summary {
                .point2-summary-title {
                  font-size: 30px;
                }

                p {
                  font-size: 24px;
                }
              }
            }
          }
        }
      }

      .nursing-skill-vr {
        .nursing-skill-vr-title {
          font-size: 58px;
        }

        .nursing-skill-vr-desc {
          font-size: 40px;
        }

        .nursing-skill-vr-list {
          .nursing-skill-vr-item {
            .nursing-skill-vr-name {
              font-size: 38px;
            }
          }
        }
      }

      .nursing-skill-btns {
        .nursing-skill-btn {
          font-size: 38px;
        }
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .NursingSkillIntro {
    .nursing-skill-intro-wrap {
      .nursing-skill-name {
        font-size: 62px;
      }

      .nursing-skill-desc {
        font-size: 32px;
      }

      .nursing-skill-point-list {
        .nursing-skill-point-item {
          .nursing-skill-point-img {
            width: 12rem;
            height: 12rem;

            img {
              max-width: 60%;
              height: auto;
              width: 100%;
            }

            p {
              font-size: 24px;
            }
          }

          .nursing-skill-point-desc {
            font-size: 20px;
            height: 12rem;
          }

          .nursing-skill-point-ex-imgs {
            .nursing-skill-point-ex-img {
              img {
                height: 10rem;
              }
            }
          }
        }
      }

      .nursing-skill-main-point-title {
        font-size: 50px;
      }

      .nursing-skill-main-point-desc {
        font-size: 40px;
      }

      .nursing-skill-main-point1 {
        .nursing-skill-main-point1-content {
          .nursing-skill-main-point1-img {
            img {
              width: 100%;
              height: auto;
            }
          }

          .nursing-skill-main-point1-summary {
            p {
              font-size: 32px;
            }
          }
        }
      }

      .nursing-skill-main-point2 {
        .nursing-skill-main-point2-content {
          .nursing-skill-main-point2-list {
            .nursing-skill-main-point2-item {
              .nursing-skill-main-point2-item-img {
                margin: 1rem;

                img {
                  width: 100%;
                  height: 14rem;
                }
              }

              .nursing-skill-main-point2-summary {
                .point2-summary-title {
                  font-size: 24px;
                }

                p {
                  font-size: 20px;
                }
              }
            }
          }
        }
      }

      .nursing-skill-vr {
        .nursing-skill-vr-title {
          font-size: 50px;
        }

        .nursing-skill-vr-desc {
          font-size: 34px;
        }

        .nursing-skill-vr-list {
          .nursing-skill-vr-item {
            .nursing-skill-vr-name {
              font-size: 32px;
            }
          }
        }
      }

      .nursing-skill-btns {
        .nursing-skill-btn {
          font-size: 32px;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .NursingSkillIntro {
    padding: 0 2rem;

    .nursing-skill-intro-wrap {
      .nursing-skill-name {
        font-size: 48px;
      }

      .nursing-skill-desc {
        padding: 0;
        font-size: 24px;
      }

      .nursing-skill-point-list {
        flex-direction: column;
        padding: 0;
        margin: 2rem 0;

        .nursing-skill-point-item {
          width: auto;
          margin-bottom: 4rem;

          .nursing-skill-point-img {
            p {
              font-size: 32px;
            }
          }

          .nursing-skill-point-desc {
            font-size: 26px;
            height: auto;
          }

          .nursing-skill-point-ex-imgs {
            .nursing-skill-point-ex-img {
              img {
                height: auto;
              }
            }
          }
        }
      }

      .nursing-skill-main-point-title {
        word-break: keep-all;
        font-size: 36px;
      }

      .nursing-skill-main-point-desc {
        font-size: 30px;
        word-break: keep-all;
      }

      .nursing-skill-main-point1 {
        .nursing-skill-main-point1-content {
          .nursing-skill-main-point1-img {
            img {
              width: 100%;
              height: auto;
            }
          }

          .nursing-skill-main-point1-summary {
            p {
              font-size: 24px;
            }
          }
        }
      }

      .nursing-skill-main-point2 {
        .nursing-skill-main-point2-content {
          .nursing-skill-main-point2-list {
            flex-direction: column;

            .nursing-skill-main-point2-item {
              width: 100%;

              .nursing-skill-main-point2-item-img {
                margin: 1rem;

                img {
                  width: 100%;
                  height: auto;
                }
              }

              .nursing-skill-main-point2-summary {
                .point2-summary-title {
                  font-size: 36px;
                }

                p {
                  font-size: 28px;
                }
              }
            }
          }
        }
      }

      .nursing-skill-vr {
        .nursing-skill-vr-title {
          font-size: 36px;
        }

        .nursing-skill-vr-desc {
          font-size: 24px;
        }

        .nursing-skill-vr-list {
          .nursing-skill-vr-item {
            .nursing-skill-vr-name {
              font-size: 22px;
            }
          }
        }
      }

      .nursing-skill-btns {
        flex-direction: column;

        .nursing-skill-btn {
          width: 100%;
          margin: 0;
          margin-bottom: 1rem;
          text-align: center;
          word-break: keep-all;
          font-size: 30px;
        }
      }
    }
  }
}

@media all and (max-width: 500px) {
  .NursingSkillIntro {
    padding: 0 1rem;

    .nursing-skill-intro-wrap {
      .nursing-skill-name {
        font-size: 36px;
      }

      .nursing-skill-desc {
        padding: 0;
        font-size: 18px;
      }

      .nursing-skill-point-list {
        flex-direction: column;
        padding: 0;
        margin: 0;

        .nursing-skill-point-item {
          width: auto;
          margin-bottom: 4rem;

          .nursing-skill-point-img {
            width: 10rem;
            height: 10rem;

            img {
              max-width: 50%;
              height: auto;
              width: 100%;
            }

            p {
              font-size: 24px;
            }
          }

          .nursing-skill-point-desc {
            font-size: 20px;
            height: auto;
          }

          .nursing-skill-point-ex-imgs {
            .nursing-skill-point-ex-img {
              img {
                height: auto;
              }
            }
          }
        }
      }

      .nursing-skill-main-point-title {
        margin-top: 2rem;
        word-break: keep-all;
        font-size: 28px;
      }

      .nursing-skill-main-point-desc {
        font-size: 20px;
        word-break: keep-all;
      }

      .nursing-skill-main-point1 {
        .nursing-skill-main-point1-content {
          .nursing-skill-main-point1-img {
            img {
              width: 100%;
              height: auto;
            }
          }

          .nursing-skill-main-point1-summary {
            p {
              word-break: keep-all;
              font-size: 18px;
            }
          }
        }
      }

      .nursing-skill-main-point2 {
        .nursing-skill-main-point2-content {
          .nursing-skill-main-point2-list {
            flex-direction: column;

            .nursing-skill-main-point2-item {
              width: 100%;

              .nursing-skill-main-point2-item-img {
                margin: 1rem;

                img {
                  width: 100%;
                  height: auto;
                }
              }

              .nursing-skill-main-point2-summary {
                .point2-summary-title {
                  font-size: 26px;
                }

                p {
                  font-size: 22px;
                }
              }
            }
          }
        }
      }

      .nursing-skill-vr {
        .nursing-skill-vr-title {
          font-size: 28px;
        }

        .nursing-skill-vr-desc {
          font-size: 18px;
        }

        .nursing-skill-vr-list {
          .nursing-skill-vr-item {
            .nursing-skill-vr-name {
              font-size: 16px;
            }
          }
        }
      }

      .nursing-skill-btns {
        flex-direction: column;

        .nursing-skill-btn {
          width: 100%;
          margin: 0;
          margin-bottom: 1rem;
          text-align: center;
          word-break: keep-all;
          font-size: 22px;
        }
      }
    }
  }
}