.ContentList {
  max-width: 80%;
  margin: 0 auto;
  margin-bottom: 11rem;
  margin-top: 2rem;
  text-align: center;

  h1 {
    font-weight: 400;
    font-size: 1.5rem;
  }

  .content-list-filters {
    margin: 1rem 0;
    button {
      border: none;
      padding: 0.875rem;
      border-radius: 6px;
      margin: 0.3rem;
      cursor: pointer;
    }

    .filter-active {
      background-color: #007edf;
      color: #fff;
    }
  }

  .content-list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .pagination {
    display: block;
    margin-top: 2rem;
    text-align: center;

    li {
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
      width: 30px;
      line-height: 30px;
      margin-right: 5px;
      margin-left: 5px;
      border: none;
      border-radius: 20px;
      cursor: pointer;
      -ms-user-select: none;
      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      user-select: none;
      outline: none;

      a {
        outline: none;
        display: block;
      }

      &:hover {
        transition: all 0.2s linear;
        background: #007edf;
        color: #ffffff;
      }
    }

    .rc-pagination-jump-prev:after,
    .rc-pagination-jump-next:after {
      content: '\2022\2022\2022';
      display: block;
      letter-spacing: 2px;
      color: #555555;
      font-size: 16px;
      margin-top: 0px;
    }

    .rc-pagination-jump-prev:after,
    .rc-pagination-jump-next:after {
      content: '\2022\2022\2022';
      display: block;
      letter-spacing: 2px;
      color: #555555;
      font-size: 16px;
      margin-top: 0px;
    }

    .rc-pagination-jump-next:hover:after {
      color: #ffffff;
      font-size: 16px;
      content: '\BB';
    }

    .rc-pagination-jump-prev:hover:after {
      color: #ffffff;
      font-size: 16px;
      content: '\AB';
    }

    .rc-pagination-item-active {
      transition: all 0.2s linear;
      background: #007edf;
      border-radius: 20px;
      color: #ffffff;
    }
  }
}

@media all and (max-width: 1200px) {
  .ContentList {
    padding: 0 1rem;

    h1 {
      font-size: 1.25rem;
    }
  }
}
