.back-to-top {
    img{
        position: fixed;
    bottom: 30px;
    right: 20px;
    font-size: 100px;
    color: white;
    cursor: pointer;
    border: none;
    }
    
  }
  