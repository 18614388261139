.ContentItem {
  width: calc(25% - 0.875rem);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  margin: 1rem 0.4rem;
  text-align: left;
  cursor: pointer;
  filter: drop-shadow(8px 21px 7px #89AEFF) drop-shadow(-8px -8px 60px rgba(137,174,255,.34));
  .content-thumb {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 60%;
    border-radius: 20px 20px 0 0;
  }

  .share-map-info {
    width: 100%;
    background: #fff;
    border-radius: 0px 0px 20px 20px;
    text-align: left;
    height: 75px;

    .share-map-title1 {
        text-align: center;
        font-size: 25px;
        color: #333333;
        line-height: 60px;
        padding-left: 20px;
        //max-width: 22rem;
        max-height: 4rem;
        font-weight: 600;
        
    }

    // .share-map-title2 {
    //     font-size: 18px;
    //     color: #333333;
    //     line-height: 25px;
    //     padding-left: 20px;
    //     padding-right: 20px;
    //     //max-width: 22rem;
    //     max-height: 4rem;
    //     font-weight: 500;
    // }

    .share-map-nickname {
        font-size: 16px;
        color: #333333;
        height: 45px;
        padding-left: 20px;
        vertical-align: top;
    }
}

  .content-bottom {
    position: relative;
    padding: 1rem;
    font-size: 0.9rem;

    .content-bottom-right {
      margin-top: 0.3rem;
    }
  }
}

@media all and (max-width: 2150px) {
  .ContentItem {
    .share-map-info{

    
  
  .share-map-title1 {
    font-size: 20px;
  }

  .share-map-title2 {
    font-size: 15px;
  }
}
}
}

@media all and (max-width: 1800px) {
  .ContentItem {
    .share-map-info{

    
  
  .share-map-title1 {
    font-size: 16px;
  }

  .share-map-title2 {
    font-size: 13px;
  }
}
}
}

@media all and (max-width: 1500px) {
  .ContentItem {
    .share-map-info{

    
  
  .share-map-title1 {
    font-size: 13px;
  }

  .share-map-title2 {
    font-size: 11px;
  }
}
}
}

@media all and (max-width: 1250px) {
  .ContentItem {
    .share-map-info{

    
  
  .share-map-title1 {
    font-size: 11px;
  }

  .share-map-title2 {
    font-size: 10px;
  }
}
}
}

@media all and (max-width: 1200px) {

  .ContentItem {
    width: calc(50% - 0.875rem);
    .share-map-info{

    
  
      .share-map-title1 {
        font-size: 16px;
      }
    
      .share-map-title2 {
        font-size: 13px;
      }
    }
  }
}
@media all and (max-width: 768px) {
  .ContentItem {
    width: calc(100% - 0.875rem);
  }
}
