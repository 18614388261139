.MainBanner {
  height: 100%;
  .main-banner-bg {
    position: relative;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 7rem;

    .main-banner-header-wrap {
      position: absolute;
      top: 6rem;
      left: 0;
      right: 0;
      padding: 0 7rem;

      .main-banner-title {
        font-size: 160px;
        color: #232323;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 0.5rem;
        word-break: keep-all;

        label {
          color: #720dda;
        }
      }

      .main-banner-desc {
        font-size: 46px;
        color: #000;
        font-weight: 500;
        line-height: 1.2;
        word-break: keep-all;
      }
    }

    .main-banner-nav {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 7rem;

      .main-banner-nav-desc {
        font-size: 36px;
        font-weight: 500;
        padding-right: 2rem;
        margin-bottom: 1rem;
        word-break: keep-all;

        label {
          color: #2051c6;
        }
      }

      .main-banner-nav-list {
        display: flex;
        margin-left: auto;

        .main-banner-nav-item {
          display: flex;
          flex-direction: column;
          min-width: 18rem;
          padding: 1.5rem 2rem;
          font-size: 40px;
          font-weight: 500;
          color: #44ffd5;
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
          cursor: pointer;

          p {
            margin-bottom: 1rem;
          }
        }

        .nursing-nav-item {
          background: rgba($color: #102dbc, $alpha: 0.8);
        }
        .health-nav-item {
          background: rgba($color: #00056a, $alpha: 0.8);
          cursor: default;
        }
        .mr-nav-item {
          background: rgba($color: #1e006a, $alpha: 0.8);
          cursor: default;
        }
      }
    }
  }
}

@media all and (max-width: 1280px) {
  .MainBanner {
    .main-banner-bg {
      .main-banner-header-wrap {
        .main-banner-title {
          font-size: 106px;
        }
        .main-banner-desc {
          font-size: 30px;
        }
      }
    }
  }
}

@media all and (max-width: 1024px), (max-height: 800px) {
  .MainBanner {
    .main-banner-bg {
      height: 100%;
      padding: 0 5rem;

      .main-banner-header-wrap {
        position: relative;
        top: 0;
        padding: 0;
        padding-top: 8rem;

        .main-banner-title {
          font-size: 86px;
        }
        .main-banner-desc {
          font-size: 24px;
        }
      }
      .main-banner-nav {
        flex-direction: column;
        align-items: start;
        position: relative;
        padding: 0;
        padding-top: 8rem;

        .main-banner-nav-desc {
          font-size: 18px;
          margin-bottom: 2.5rem;
        }
        .main-banner-nav-list {
          width: 100%;
          flex-direction: column;

          .main-banner-nav-item {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            min-width: unset;
            width: 100%;
            margin-bottom: 1.5rem;
            border-radius: 16px;

            p {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .MainBanner {
    .main-banner-bg {
      padding: 0 4rem;

      .main-banner-header-wrap {
        padding-top: 6rem;

        .main-banner-title {
          font-size: 70px;
        }
        .main-banner-desc {
          font-size: 24px;
        }
      }
      .main-banner-nav {
        padding-top: 6rem;

        .main-banner-nav-list {
          .main-banner-nav-item {
            font-size: 32px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 500px) {
  .MainBanner {
    .main-banner-bg {
      padding: 0 2rem;

      .main-banner-header-wrap {
        padding-top: 4rem;
        .main-banner-title {
          font-size: 54px;
        }
        .main-banner-desc {
          font-size: 16px;
        }
      }
      .main-banner-nav {
        padding-top: 4rem;
        .main-banner-nav-list {
          .main-banner-nav-item {
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
    }
  }
}
