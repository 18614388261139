.dying-header-wrapp {
  height: 100px;
  position: fixed;
  width: 100%;
  z-index: 9999;
  background: black;

  .header-box {
    display: flex;
    justify-content: flex-start;
    height: 100%;
    padding: 0 10px;
    margin: 0 auto;
    align-items: center;
    max-width: 80%;
  }

  .logo {
    float: left;

    img {
      vertical-align: middle;
    }
  }

  .nav {
    margin-left: 30px;
    ul {
      // display: flex;
      // align-items: center;
      // text-align: center;
      // flex-wrap: nowrap;
      .nav-li {
        flex: 1;
        float: left;
        margin: 0 0.8rem;
        font-size: 1rem;
        word-break: keep-all;
        // max-width: 116px;

        &:nth-child(1) {
          margin-left: 1.2rem;
        }

        .nav-link {
          text-align: center;
          color: #fff;
          cursor: pointer;
          
        }
      }


    }
  }

  .nav-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    li {
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      margin-left: 10px;
      margin-right: 0;

      button {
        width: 100px;
        line-height: 35px;
        border: 1px solid #ffffff;
        background: none;
        color: #ffffff;
        border-radius: 17.5px;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }


  .dropDown{
    font-size: 2rem;
    margin-right: 3rem;
    margin-top: -5rem;
    float: right;
    button{
      font-size: 2rem;
    }
    article{
      background-color: lightskyblue;
    }
  }

}

.nav-button{
  width: 75rem;
  height: 4rem;
  border-radius: 20px;
  font-size: 3rem;
  border: none;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.none {
  display: none;
}

.mobile-menu {
  float: right;
  top: 0;
  scrolltop: false;
  display: none;
}

.menu-trigger {
  width: 30px;
  height: 30px;
  display: inline-block;
  position: fixed;
  z-index: 10;
  top: 45px;
  right: 32px;
  cursor: pointer;
}

/* These are the icon lines */

.menu-trigger span {
  position: absolute;
  display: inline-block;
  height: 2px;
  width: 100%;
  background: #ffffff;
  transition: all 0.3s linear;
}

.menu-trigger span:nth-child(1) {
  top: 0px;
}

/* Line 2 and three are sitting on each other so we can animate an X from the center */

.menu-trigger span:nth-child(2),
.menu-trigger span:nth-child(3) {
  top: 8px;
}

.menu-trigger span:nth-child(4) {
  top: 16px;
}

/* This is the X Icon */

.icon-active span:nth-child(1),
.icon-active span:nth-child(4) {
  opacity: 0;
}

/* Second line rotating 45 Deg */
.icon-active span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Third line rotating -45 Deg */
.icon-active span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.links {
  color: white;
  top: 100%;
  left: 0;
  right: 0;
  position: absolute;
  text-decoration: none;
  list-style: none;
  height: 0;
  overflow: hidden;
  background: #007edf;
  width: 100%;
  /* border-bottom: 4px solid white; */
  text-align: center;
  opacity: 0;
  // -webkit-transition: all .3s linear 0s;
  // transition: all .3s linear 0s;
}

.links ul li {
  list-style: none;
  margin: 1em;
  // padding-top: 2em;
  font-size: 2em;
  line-height: 75px;
}

.links li:nth-child(1) {
  padding-top: 0;
}

.links li:last-child {
  padding-bottom: 1em;
}

.links a {
  text-decoration: none;
  color: white;
}

.toggle {
  height: auto;
  opacity: 1;
}

.cotton {
  position: fixed;
  height: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  z-index: 1000;
}

.cotton-toggle {
  height: 100%;
}

.sticky {
  background: #007edf !important;
  box-shadow: 0px -16px 56px -4px #333;
}

@media all and (min-width: 1200px) {
  .header-box {
    max-width: 80%;
  }
}

@media all and (max-width: 1024px) {
  .header-wrap {
    .nav {
      display: none;
    }

    .nav-right {
      display: none;
    }

    .mobile-menu {
      display: block;
    }
  }
}

@media all and (max-width: 768px) {
  .header-wrap {
    .header-box {
      padding: 0 2.666666666666667em;
    }

    .logo {
      img {
        width: 70%;
      }
    }

    .nav {
      display: none;
    }

    .nav-right {
      display: none;
    }

    .mobile-menu {
      display: block;
    }
  }
}

@media all and (max-width: 768px) {
  .header-wrap {
    height: 75px;

    .header-box {
      line-height: 75px;
    }
  }

  .menu-trigger {
    top: 30px;
  }
}

@media all and (max-width: 500px) {
  .links ul li {
    margin: 0em;
    font-size: 1.5em;
  }
}
