.SchoolDownload {
    .download-headline {
        margin-top: 80px;
        padding-bottom: 10px;

        p {
            font-size: 30px;
            font-weight: 500;
            color: #333333;
        }
    }

    .school-download-wrap {
        padding-top: 40px;
        display: flex;
        padding-bottom: 40px;

        .school-download-pc {
            width: 100%;
            padding-right: 10px;
            

            .school-download-pc-button {
                button {
                    width: 80%;

                    border: none;
                    background: #4A3AFF;
                    border-radius: 10px;
                    font-size: 30px;
                    font-weight: bold;
                    text-align: center;
                    color: white;
                    height: 100px;
                    padding: 0 5%;
                    cursor: text;
                    margin-bottom: 40px;;


                    img {
                        vertical-align: bottom;
                        float: right;
                    }
                }
            }

            .school-download-pc-label {
                margin-top: 5px;
                font-size: 16px;
                color: #555555;
            }
        }

        .school-download-hmd {
            width: 50%;
            padding-left: 10px;

            .school-download-hmd-button {


                button {
                    width: 100%;
                    border: none;
                    background: #2fae8e;
                    border-radius: 10px;
                    font-size: 24px;
                    font-weight: bold;
                    text-align: left;
                    color: #ffffff;
                    height: 100px;
                    padding: 0 5%;
                    cursor: pointer;

                    &:hover {
                        background: #219376;
                    }

                    img {
                        vertical-align: bottom;
                        float: right;
                    }
                }
            }

            .school-download-hmd-label {
                margin-top: 5px;
                font-size: 16px;
                color: #555555;
            }
        }


    }
}

@media all and (max-width:1024px) {
    .SchoolDownload {
        .school-download-wrap {
            flex-direction: column;


            .school-download-pc {
                width: 100%;
                padding: 0 10px;
                .school-download-pc-button{
                    width: 100%;
                    button{
                        width: 100%;
                    }
                }
            }

            .school-download-hmd {
                width: 100%;
                padding: 0 10px;
            }
        }
    }
    
}


@media all and (max-width:768px) {

    .SchoolDownload {
        .download-headline {
            p {
                font-size: 24px;
                text-align: center;
            }
        }

        .school-download-wrap {
            flex-direction: column;

            .school-download-pc {
                width: 100%;
                padding: 0 10px;

                .school-download-pc-label {
                    font-size: 12px;
                }

                .school-download-pc-button {
                    button {
                        font-size: 20px;
                        height: 80px;

                        img {
                            width: 30px;
                        }
                    }
                }
            }

            .school-download-hmd {
                width: 100%;
                padding: 0 10px;
                margin-top: 3%;

                .school-download-hmd-label {
                    font-size: 12px;
                }

                .school-download-hmd-button {
                    button {
                        font-size: 20px;
                        height: 80px;

                        img {
                            width: 30px;
                        }
                    }
                }
            }
        }
    }
}

@media all and (max-width:500px) {
    .SchoolDownload {
        .download-headline {
            p {
                font-size: 20px;
            }
        }

        .school-download-wrap {
            .school-download-pc {
                .school-download-pc-button {
                    button {
                        font-size: 16px;

                        img {
                            width: 25px;
                        }
                    }
                }
            }

            .school-download-hmd {
                .school-download-hmd-button {
                    button {
                        font-size: 16px;

                        img {
                            width: 25px;
                        }
                    }
                }

            }
        }
    }
}