.DropDownContainer{

    position: absolute;
    top: 1em;
    right: 4em;
    background-color:white;
    height: 30px;
    width: 100px;
    &:hover{
      cursor:pointer;
    }
        .DropDownHeader{
            text-align: center;
            font-size: 1.3rem;

        .DropDownListContainer{
            .DropDownList{

            height: 140px;
            width: 120px;
            top: 30px;
            background-color: rgb(255,255,255);
            line-height: 20px;
            border-radius: 10px;
            color: rgb(115,115,115);
            font-size: 15px;
            font-weight: 500;
            position: relative;
            left: -10px;
            z-index: 100;
            text-align: center;
            
            ::before {
              border-color: rgb(255, 255, 255) transparent;
              border-style: solid;
              border-width: 0px 6px 8px 6.5px;
              content: "";
              display: block;
              left: 50px;
              position: absolute;
              top: -7px;
              width: 0px;
              z-index: 0;
            }


                .ListItem{
                    list-style: none;
                    margin-bottom: 0.8em;
                    padding: 3px 0;
                    :hover{
                      color: #FCC500
                    }

                }
            }
        }
    }
}