@font-face {
  font-family: 'Recipekorea';
  font-weight: 400;
  font-style: normal;
  src: url('.//Recipekorea.otf') format('opentype'),
    url('./Recipekorea.ttf') format('ttf');
}


.NursingSkillBanner {

  .nursing-skill-banner-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    height: 40rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .nursing-skill-banner-txt-wrap {
      text-align: center;
      padding-top: 6rem;

      .nursing-skill-banner-title {
        font-size: 5.5rem;
        color: #fff;
        font-family: 'Recipekorea';
        text-shadow: 0px 4px 30px rgba(54, 20, 149, 0.45);
      }
      .nursing-skill-banner-desc {
        word-break: keep-all;
        font-size: 2.5rem;
        color: #fff;
      }
      .nursing-skill-banner-btns {
        display: flex;
        justify-content: center;
        margin: 3rem 0;
        width: 100%;
        margin-bottom: 0rem;

        .nursing-skill-banner-btn {
          display: inline-block;
          border-radius: 15px;
          padding: 1.3rem 2rem;
          color: #fff;
          font-size: 2rem;
          cursor: pointer;
        }
        .btn-left {
          margin-right: 1rem;
          background: #20CBBA;
          display: flex;
          width: 35%;
          
          button{
            width: 100%;
            border: none;
            background: #20CBBA;
            font-size: 18px;
            color: white;
            align-items: center;
            justify-content: space-between;
            display: flex;
          }

          img {
            vertical-align: bottom;
            float: right;
          }
        }
        .btn-right {
          margin-left: 1rem;
          width: 35%;
          background: #D764FF;
          display: flex;

          button{
            width: 100%;
            border: none;
            background: #D764FF;
            font-size: 18px;
            color: white;
            align-items: center;
            justify-content: space-between;
            display: flex;
          }

          img {
            vertical-align: bottom;
            float: right;
          }
        }
      }


      .nursing-skill-banner-bottom-btns {
        display: flex;
        justify-content: center;
        margin: 3rem 0;
        width: 100%;
        margin-top: 1.5rem;

        .nursing-skill-banner-bottom-btn {
          display: inline-block;
          border-radius: 15px;
          padding: 1.3rem 2rem;
          color: #fff;
          font-size: 2rem;
          cursor: pointer;
        }
        .btn {
          background: #8168FF;
          display: flex;
          width: 74%;
          border: 1px solid #FFFFFF;
          
          button{
            width: 100%;
            border: none;
            background: #8168FF;
            font-size: 18px;
            color: white;
            align-items: center;
            justify-content: space-between;
            display: flex;
          }

          img {
            vertical-align: bottom;
            float: right;
          }
        }
      }


    }
  }
}

@media all and (max-width: 1280px) {
  .NursingSkillBanner {
    .nursing-skill-banner-bg {
      height: 36rem;
      .nursing-skill-banner-txt-wrap {
        .nursing-skill-banner-title {
          font-size: 94px;
        }
        .nursing-skill-banner-desc {
          font-size: 48px;
        }

        .nursing-skill-banner-btns {
          .nursing-skill-banner-btn {
            font-size: 22px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .NursingSkillBanner {
    .nursing-skill-banner-bg {
      height: 34rem;
      .nursing-skill-banner-txt-wrap {
        .nursing-skill-banner-title {
          font-size: 80px;
        }
        .nursing-skill-banner-desc {
          font-size: 40px;
        }

        .nursing-skill-banner-btns {
          .nursing-skill-banner-btn {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .NursingSkillBanner {
    .nursing-skill-banner-bg {
      height: 30rem;
      .nursing-skill-banner-txt-wrap {
        .nursing-skill-banner-title {
          font-size: 70px;
        }
        .nursing-skill-banner-desc {
          font-size: 36px;
        }

        .nursing-skill-banner-btns {
          .nursing-skill-banner-btn {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 500px) {
  .NursingSkillBanner {
    .nursing-skill-banner-bg {
      height: 28rem;
      .nursing-skill-banner-txt-wrap {
        .nursing-skill-banner-title {
          font-size: 52px;
        }
        .nursing-skill-banner-desc {
          font-size: 26px;
        }

        .nursing-skill-banner-btns {
          padding: 0 2rem;
          flex-direction: column;
          .nursing-skill-banner-btn {
            width: 100%;
            margin: 0;
            margin-bottom: 1rem;
            font-size: 16px;
          }
        }
      }
    }
  }
}
